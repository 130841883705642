var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("head-layout", {
            attrs: {
              "head-btn-options": _vm.headBtnOptions,
              "head-title": _vm.$t("cip.dc.projectlist.title.name"),
            },
            on: { "head-add": _vm.AddRw },
          }),
          _c(
            "div",
            {
              staticStyle: {
                height: "75vh",
                width: "100%",
                "background-color": "#fff",
                "overflow-y": "scroll",
              },
            },
            [
              _c(
                "div",
                { staticClass: "listBox" },
                _vm._l(_vm.DictList, function (item) {
                  return _c("div", { key: item.id, staticClass: "box1" }, [
                    _c("img", {
                      staticStyle: { width: "35px", height: "35px" },
                      attrs: { src: require("../img/projects.png"), alt: "" },
                    }),
                    _c("div", { staticStyle: { "margin-left": "15px" } }, [
                      _c(
                        "div",
                        { staticStyle: { "font-size": "16px", margin: "5px" } },
                        [_vm._v(_vm._s(item.name))]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            color: "darkgray",
                            margin: "5px",
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.zh_name) +
                              "\n            "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "span",
                            {
                              staticClass: "textbt",
                              on: {
                                click: function ($event) {
                                  return _vm.MRunflow(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("cip.dc.projectlist.listedit.dispatch")
                                ) + "  "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "textbt",
                              on: {
                                click: function ($event) {
                                  return _vm.MSee(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("cip.dc.projectlist.listedit.see")
                                ) + "  "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "textbt",
                              on: {
                                click: function ($event) {
                                  return _vm.Mfabu(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("cip.dc.projectlist.listedit.release")
                                ) + "  "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "textbt",
                              on: {
                                click: function ($event) {
                                  return _vm.Mrwgl(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "cip.dc.projectlist.listedit.TaskManagement"
                                  )
                                ) + "\n                 "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "textbt",
                              on: {
                                click: function ($event) {
                                  return _vm.MEdit(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("cip.dc.projectlist.listedit.edit")
                                ) + "  "
                              ),
                            ]
                          ),
                          _c(
                            "el-popconfirm",
                            {
                              attrs: {
                                "confirm-button-text": "Yes",
                                "cancel-button-text": "No",
                                icon: "el-icon-info",
                                "icon-color": "red",
                                title: _vm.$t("cip.dc.projectlist.msg.msg1"),
                              },
                              on: {
                                confirm: function ($event) {
                                  return _vm.DelTask(item)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "textbt",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "cip.dc.projectlist.listedit.delete"
                                      )
                                    ) + "  "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("cip.dc.projectlist.title.name"),
                visible: _vm.dialog1,
                width: "80%",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialog1 = $event
                },
                close: _vm.close1,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "160px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t(
                          "cip.dc.projectlist.seetabs.editlabel.Englishname"
                        ),
                        prop: "name",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.DisName },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t(
                          "cip.dc.projectlist.seetabs.editlabel.Chinesename"
                        ),
                        prop: "zh_name",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.zh_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "zh_name", $$v)
                          },
                          expression: "ruleForm.zh_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t(
                          "cip.dc.projectlist.seetabs.editlabel.Contentdescription"
                        ),
                        prop: "content",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.ruleForm.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "content", $$v)
                          },
                          expression: "ruleForm.content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialog1 = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("cancelText")) + "\n        ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("submitText")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("cip.dc.projectlist.seetabs.InformationService"),
                visible: _vm.dialog2,
                width: "60%",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialog2 = $event
                },
              },
            },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.$t("cip.dc.projectlist.seetabs.flowChart"),
                        name: "first",
                      },
                    },
                    [_c("g2")],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.$t("cip.dc.projectlist.seetabs.taskList"),
                        name: "second",
                      },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.Seedata.resFetchexecflow.nodes,
                            border: "",
                            "default-sort": { prop: "startTime" },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "nestedId",
                              label: _vm.$t(
                                "cip.dc.projectlist.seetabs.taskListlabel.name"
                              ),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "type",
                              label: _vm.$t(
                                "cip.dc.projectlist.seetabs.taskListlabel.type"
                              ),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "startTime",
                              label: _vm.$t(
                                "cip.dc.projectlist.seetabs.taskListlabel.startTime"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      _vm._s(
                                        new Date(
                                          scope.row.startTime
                                        ).toLocaleString()
                                      )
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "endTime",
                              label: _vm.$t(
                                "cip.dc.projectlist.seetabs.taskListlabel.Endtime"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      _vm._s(
                                        new Date(
                                          scope.row.endTime
                                        ).toLocaleString()
                                      )
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "address",
                              label: _vm.$t(
                                "cip.dc.projectlist.seetabs.taskListlabel.timeConsuming"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      _vm._s(
                                        (new Date(scope.row.endTime) -
                                          new Date(scope.row.startTime)) /
                                          1000 +
                                          "s"
                                      )
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "status",
                              label: _vm.$t(
                                "cip.dc.projectlist.seetabs.taskListlabel.state"
                              ),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t(
                                "cip.dc.projectlist.seetabs.taskListlabel.details"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            _vm.dialog3 = true
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "cip.dc.projectlist.seetabs.taskListlabel.details"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.$t("cip.dc.projectlist.seetabs.ProcessLog"),
                        name: "third",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { "white-space": "pre-line" } },
                        [_vm._v(_vm._s(_vm.Seedata.resLog.data))]
                      ),
                    ]
                  ),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        title: _vm.$t("cip.dc.projectlist.seetabs.Joblog"),
                        visible: _vm.dialog3,
                        width: "60%",
                        "append-to-body": true,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.dialog3 = $event
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { "white-space": "pre-line" } },
                        [_vm._v(_vm._s(_vm.Seedata.resLog.data))]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.dialog3 = false
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("cip.dc.projectlist.btn.sure"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialog2 = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("cip.dc.projectlist.btn.close")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }